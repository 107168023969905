import { createApp } from 'vue';
import groupSearchRegion from '../vue-component/groupSearchRegion.vue';
import groupSearchActiveRegion from '../vue-component/groupSearchActiveRegion.vue';

const app = createApp({});
app.component('group-search-region', groupSearchRegion);
app.mount('#search-region')

const app2 = createApp({});
app2.component('group-search-active-region', groupSearchActiveRegion);
app2.mount('#search-active-region')
