<script setup>
import { ref, reactive, defineProps } from 'vue'

const props = defineProps(
    ['region', 'active2region', 'pref', 'region2pref', 'action', 'token', 'suffix']
)

const region = props.region;
const activeRegion = props.active2region;
const pref = props.pref;
const region2Pref = props.region2pref;
const action = props.action;
const suffix = props.suffix ? props.suffix : '';
let regionId = ref(null);
const list = reactive({
    pref: {}
});

const setPrefList = (id) => {
    if(!regionId || regionId != id){
        list.pref = {};
        region2Pref[id].map((key) => {
            list.pref[key] = pref[key];
        });
        regionId = id;
    }else{
        regionId = null
    }
}

const execSearch = (event) => {
    event.target.closest('form').submit();
}
</script>

<template>
    <ul class="p-home-area__list">
        <li v-for="(count, id) in activeRegion" class="p-home-area__item">
            <form :action="action">
                <div class="p-home-area__btn c-btn--white --bottom" @click="setPrefList(id)">
                    <span>{{region[id]}}({{count}}件)</span>
                </div>
                <input type="hidden" name="region" v-model="regionId">
                <div v-show="Object.keys(list.pref).length != 0 && id == regionId" class="c-search__locale">
                    <div class="c-search__locale__inner">
                        <label v-for="(data, id) in list.pref" @click="execSearch">
                            <input type="radio" name="pref" :value="id">
                            <span>{{data}}</span>
                        </label>
                    </div>
                </div>
            </form>
        </li>
    </ul>
</template>
