<script setup>
import { reactive, defineProps } from 'vue'

const props = defineProps(
    ['region', 'pref', 'region2pref', 'action', 'token', 'suffix', 'keyword_action']
)

const region = props.region;
const pref = props.pref;
const region2Pref = props.region2pref;
const action = props.action;
const keywordAction = props.keyword_action ? props.keyword_action : '';
const suffix = props.suffix ? props.suffix : '';

const list = reactive({
    pref: {}
});

const setPrefList = (event) => {
    list.pref = {};
    region2Pref[event.target.value].map((key) => {
        list.pref[key] = pref[key];
    });
}

const execSearch = (event) => {
    event.target.closest('form').submit();
}
</script>

<template>
    <div class="c-search">
        <div class="c-search__inner">
            <form :action=action method="GET">
                <h3 class="c-search__heading">
                    ママとこどものはいしゃさん<br class="u-sp_min_none">グループ院の検索はこちらから
                </h3>
                <div class="c-search__wrap">
                    <div class="c-search__current c-btn --width">
                        <a :class="suffix" href="/map/">
                            <span :class="suffix">現在地（マップ）<br class="u-sp_min_none">から探す</span>
                        </a>
                    </div>
                    <div class="c-search__area c-btn --bottom --width">
                        <select :id="'region' + suffix" name="region" @change="setPrefList" aria-label="エリアから探す">
                            <option value="">都道府県から探す</option>
                            <option v-for="(data, id) in region" :value="id">{{ data }}</option>
                        </select>
                    </div>
                </div>
                <div v-show="Object.keys(list.pref).length != 0" class="c-search__locale">
                    <div class="c-search__locale__inner">
                        <label v-for="(data, id) in list.pref" @click="execSearch">
                            <input :id="'pref' + suffix" type="radio" name="pref" :value="id">
                            <span>{{ data }}</span>
                        </label>
                    </div>
                </div>
            </form>
            <form :action=keywordAction method="GET">
                <div class="c-search__keyword">
                    <input type="text" name="keyword" placeholder="キーワードから探す（地域名、医院名、駅名など）" @keyup.enter="execSearch(event)">
                    <div class="c-search__btn c-btn" @click="execSearch">
                        <a href="javascript:void(0)">検索</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
